import React, { Component } from "react";
import { Modal, Button } from "antd";
import { BlogMain } from "./blog-style";
import ReactQuill from "react-quill";

export default class BlogPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.dataDetail,
    };
  }

  handleClose = (event) => {
    this.props.onClose(null);
  };

  render() {
    return (
      <Modal
        centered
        visible={this.props.visible}
        footer={null}
        onCancel={this.handleClose}
        className="single-post-modal"
      >
        <BlogMain className="full-width single-post-page">
          <div className="post-box">
            <h1>{this.state.data.title}</h1>
            <div className="single-post-social-icons full-width auth-copyrights relative">
              <ul>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/fanifymusic/"
                  >
                    <i className="fa fa-facebook" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    href="https://www.instagram.com/fanifymusic/"
                    target="_blank"
                  >
                    <i className="fa fa-instagram" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://twitter.com/FanifyMusic"
                  >
                    <i className="fa fa-twitter" aria-hidden="true"></i>
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/fanify-music-59a76121b/"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="single-post-right full-width">
              <div
                className="post-image full-width bgimg-main"
                style={{
                  backgroundImage: `url(${this.state.data.blogImageUrl})`,
                }}
              >
                <span className="featured-text">Featured</span>
                <img src={`${this.state.data.blogImageUrl}`} />
              </div>
              <div className="post-content full-width">
                <ReactQuill
                  value={this.state.data.description}
                  readOnly={true}
                  theme={"bubble"}
                />
              </div>
            </div>
          </div>
        </BlogMain>
      </Modal>
    );
  }
}
