import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { apiConst } from '../../constants/api';
import Api from '../../utility/apiHandler';
import Login from '../authentication/login/login';
import Signup from '../authentication/signup/signup';
import { openNotificationWithIcon } from '../openNotification/openNotification';
import Header from '../shared/header/header';
import BlogPopup from './blog-popup';
import { BlogMain } from './blog-style';
import moment from 'moment'

const api = new Api()

const Blog = () => {
    const [state, setState] = useState({
        allData: [],
        page: 1,
        limit: 6,
        totalDocs: null,
        blogPopupVisible: false,
        isLoginModalVisible:false,
        isSignupModalVisible:false,
        dataDetail: {},
      })
      const history = useHistory()
      const getAllData = async () => {
        api
          .post(
            apiConst.getBlogList
              .replace(":page", state.page)
              .replace(":limit", state.limit)
          )
          .then((res) => {
            if (res.status === 200) {
              // console.log(res.data);
              setState({...state,
                allData: res.data.docs,
                page: res.data.page,
                limit: res.data.limit,
                totalDocs: res.data.totalDocs,
              });
            }
          })
          .catch((e) => {
            // console.log("error", e);
          });
      };
      const copyText = () => {
        openNotificationWithIcon("success", "Success", "Copied to clipboard");
      };
      
      const OpenblogPopup = (e, element) => {
        history.push(`blog/${element.pageId}`); 
      };
    
      const CloseblogPopup = () => {
        setState({...state, blogPopupVisible: false });
      };

      const handleLoginModal = (bool) => { setState({...state, isLoginModalVisible : bool }) }

      const handleSignupModal = (bool) => { setState({...state, isSignupModalVisible: bool }) }
      
      useEffect(() => {
        getAllData()
      },[state.page])

      const handlePageChange = (page) => {
        setState({...state, page})
      }

  return (
    <>
    <Header handleLoginModal={handleLoginModal} handleSignupModal={handleSignupModal} />
    <Login isModalVisible={state.isLoginModalVisible} handleModal={handleLoginModal} />
    <Signup isModalVisible={state.isSignupModalVisible} handleModal={handleSignupModal} />
    <section className="aboutus background1">

    <div className="container">

        <BlogMain className="full-width">
          <div className="wrapper">
            <h1 className="title-with-border full-width">Blogs</h1>
            <div className="full-width blog-listing-main">
              {state.allData.length > 0 &&
                state.allData.map((element, index) => (
                  <div className="post-box" key={element._id}>
                    <a
                      className="post-image full-width bgimg-main"
                      style={{
                        backgroundImage: `url(${element.blogImageUrl})`,
                      }}
                      role="presentation"
                      onClick={(e) => OpenblogPopup(e, element)}
                    >
                      <span className="featured-text">Featured</span>
                      <img src={`${element.blogImageUrl}`} />
                    </a>
                    <div className="post-topic full-width flex-center">
                      <span>
                        Posted at{" "}
                        {moment.utc(element.createdAt).format("DD-MM-YYYY")}
                      </span>
                    </div>
                    <div className="post-content full-width">
                      <h4>
                        <a
                          role="presentation"
                          onClick={(e) => OpenblogPopup(e, element)}
                        >
                          {element.title}
                        </a>
                        <CopyToClipboard
                          text={`https://fanify.io/blog/${element.pageId}`}
                          onCopy={(e) => copyText(e)}
                          style={{ cursor: "pointer" }}
                        >
                          <a className="tooltip-main">
                            <div className="tooltip-text">Copy Post URL</div>
                            <i className="fa fa-clone" aria-hidden="true"></i>
                          </a>
                        </CopyToClipboard>
                      </h4>
                      <div
                        className="full-width post-cta"
                        role="presentation"
                        onClick={(e) => OpenblogPopup(e, element)}
                      >
                        <a className="default-cta">
                          <span>Read More</span>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="blog-pagination full-width">
              <Pagination
                onChange={(page) => handlePageChange(page)}
                pageSize={state.limit}
                current={state.page}
                total={state.totalDocs}
              />
            </div>
          </div>
          {state.blogPopupVisible && (
            <BlogPopup
              visible={state.blogPopupVisible}
              onClose={CloseblogPopup}
              dataDetail={state.dataDetail}
            />
          )}
        </BlogMain>

    </div>    

      </section>
    </>
  )
}

export default Blog