import styled from "styled-components";

export const BlogMain = styled.div`
  &.single-post-page {
    padding: 0 15px 15px;
    @media (max-width: 768px) {
      padding: 0 0px 15px;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      margin: 0 0 15px 0 !important;
      padding: 0 !important;
    }
    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5 {
      padding-top: 22px !important;
    }
    h1 {
      margin: 0 0 25px 0 !important;
    }
    .post-box {
      width: 100% !important;
      margin: 0 !important;
      .post-content {
        a {
          text-decoration: underline;
        }
      }
      .post-image {
        height: 300px;
        @media (max-width: 500px) {
          height: 300px;
        }
        img {
          max-width: 100%;
          width: 100%;
          height: 400px;
          object-fit: cover;
        }
      }
    }
  }
  .title-with-border {
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
  }
  padding: 40px 0;
  .post-box {
    float: none;
    vertical-align: top;
    display: inline-block;
    margin: 20px 36px 35px -3px;
    &:nth-child(3n) {
      margin-right: 0;
    }
    width: 31.5%;
    @media (max-width: 1240px) {
      width: 31%;
      margin: 20px 35px 35px -3px;
      @media (max-width: 992px) {
        margin: 15px 35px 30px -3px;
        width: 30.4%;
        @media (max-width: 767px) {
          margin: 15px 34px 30px -2px;
          width: 47.2%;
          &:nth-child(3n) {
            margin-right: 34px;
          }
          &:nth-child(2n) {
            margin-right: 0px;
          }
          @media (max-width: 600px) {
            margin: 15px 0px 20px 0px !important;
            width: 100%;
          }
        }
      }
    }

    .post-image {
      height: 290px;
      @media (max-width: 992px) {
        height: 280px;
        @media (max-width: 767px) {
          height: 270px;
          @media (max-width: 600px) {
            height: 370px;
            @media (max-width: 400px) {
              height: 350px;
            }
          }
        }
      }
      border-radius: 5px;
      position: relative;
      .featured-text {
        position: absolute;
        left: -10px;
        top: 20px;
        background: #2ea5c2;
        padding: 4px 10px;
        line-height: normal;
        font-size: 12px;
        width: 90px;
        padding-left: 15px;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        letter-spacing: 1px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
          rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        &:after {
          position: absolute;
          left: 0;
          bottom: -10px;
          width: 0;
          height: 0;
          border-left: 11px solid transparent;
          border-right: 0px solid transparent;
          border-top: 10px solid #027fc5;
          content: "";
        }
      }
    }
    .post-topic {
      border: 1px solid #ccc;
      padding: 6px 15px;
      margin-top: 20px;
      border-radius: 5px;
      line-height: normal;
      font-size: 14px;
      .topic {
        text-transform: uppercase;
      }
    }
    .post-content {
      padding-top: 15px;
      line-height: normal;
      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      h4 {
        margin-bottom: 10px !important;
        a {
          color: #000;
          &:hover {
            color: #027fc5;
          }
        }
      }
      .post-cta {
        width: auto !important;
        .default-cta {
          margin-top: 10px !important;
          padding: 0 15px !important;
          height: 35px !important;
          min-width: 80px;
          line-height: 28px !important;
        }
      }
    }
  }
  .blog-pagination {
    float: right;
    width: auto;
    margin: 20px 0 10px;
    .ant-pagination-item-active {
      border-color: #92fbbc;
      a {
        background: #2ea5c2;
        color: #fff;
      }
    }
    @media (max-width: 767px) {
      width: 100%;
      display: flex;
      margin: 10px 0 0px;
      justify-content: center;
      .ant-pagination {
        width: auto;
        li {
          margin: 5px;
        }
      }
    }
  }
`;
